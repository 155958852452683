<template>
    <div class="refund-container card-tail">
        <ConfirmationModal code-confirmation @success="getData" />

        <div class="flex justify-between border-b pb-2 mb-4">
            <h1>Återbetalning</h1>
            <p v-if="formData.refundSentDate" class="bg-green-500 text-white px-4 py-1 rounded-lg text-sm">Skickat: {{ formData.refundSentDate }} av {{ formData.creatorName }}</p>
        </div>

        <div class="px-16 pb-8" v-loading="$waiting.is('refund.loading')">
            <el-form ref="form" :model="formData" :rules="validationRules" :hide-required-asterisk="true">
                <el-form-item label="Anledning" prop="reason">
                    <el-input v-model="formData.reason" :disabled="formData.refundStatus === 2" />
                </el-form-item>

                <el-form-item label="Betalnings typ" prop="accountType">
                    <el-select v-model="formData.accountType" class="w-full" :disabled="formData.refundStatus === 2">
                        <el-option v-for="item in accountTypes" :key="item.value" :value="parseInt(item.value)" :label="item.name" />
                    </el-select>
                </el-form-item>

                <el-form-item label="Konto nr" prop="accountNumber">
                    <el-input v-model="formData.accountNumber" :disabled="formData.refundStatus === 2" />
                </el-form-item>

                <el-form-item label="Mottagare" prop="recipientName">
                    <el-input v-model="formData.recipientName" :disabled="formData.refundStatus === 2" />
                </el-form-item>

                <el-form-item label="Kontaktperson" prop="contactPerson">
                    <el-input v-model="formData.contactPerson" :disabled="formData.refundStatus === 2" />
                </el-form-item>

                <el-form-item label="Belopp" prop="amount">
                    <el-input v-model="formData.amount" disabled />
                </el-form-item>

                <el-form-item label="Meddelande" prop="message">
                    <el-input v-model="formData.message" :disabled="formData.refundStatus === 2" />
                </el-form-item>

                <el-form-item label="Intern notering" prop="comment">
                    <el-input type="textarea" rows="3" resize="none" v-model="formData.comment" :disabled="formData.refundStatus === 2" />
                </el-form-item>
            </el-form>
        </div>
        <div class="flex justify-end">
            <el-button v-if="formData.refundStatus <= 1" @click="saveDraft" type="primary" :loading="$waiting.is('refund.sending')">Spara utkast</el-button>
            <el-button v-if="formData.refundStatus <= 1" @click="markAsSent" type="danger" :loading="$waiting.is('refund.sending')">Markera som skickat</el-button>
            <el-button v-if="formData.refundStatus < 2" @click="refund" type="success" :loading="$waiting.is('refund.sending')">Skicka</el-button>
            <el-button v-if="formData.refundStatus === 1" @click="deleteRefund" type="primary" :loading="$waiting.is('refund.*')">Ta bort</el-button>
        </div>
    </div>
</template>
<script>
import Api from "../bank_files.api";
export default {
    props: {
        bankFileId: {
            type: String,
            default: null,
        },
    },

    data() {
        return {
            activeValidationRules: "saving",
            accountTypes: [],
            formData: {
                reason: null,
                accountType: 0,
                accountNumber: null,
                recipientName: null,
                contactPerson: null,
                amount: null,
                currency: null,
                message: null,
                comment: null,
            },
            rulesForSaving: {},
            rulesRulesForSending: {
                reason: [{ required: true, message: "Nödvändig", trigger: "blur" }],
                accountType: [{ required: true, message: "Nödvändig", trigger: "blur" }],
                accountNumber: [{ required: true, message: "Nödvändig", trigger: "blur" }],
                recipientName: [{ required: true, message: "Nödvändig", trigger: "blur" }],
                currency: [{ required: true, message: "Nödvändig", trigger: "blur" }],
                message: [{ required: true, message: "Nödvändig", trigger: "blur" }],
            },
        };
    },

    computed: {
        validationRules() {
            if (this.activeValidationRules === "saving") {
                return this.rulesForSaving;
            }
            return this.rulesRulesForSending;
        },
    },

    watch: {
        validationRules() {
            this.$refs.form.clearValidate();
        },
    },

    created() {
        this.getAccountTypes();
        this.getData();
    },

    methods: {
        async getAccountTypes() {
            this.accountTypes = await Api.getAccountTypes();
        },

        async getData() {
            this.$waiting.start("refund.loading");
            this.formData = await Api.getRefund(this.bankFileId);
            this.$waiting.end("refund.loading");
        },

        async saveDraft() {
            this.activeValidationRules = "saving";
            await this.$nextTick();
            await new Promise(resolve => this.$refs.form.validate(valid => valid && resolve()));

            this.$waiting.start("refund.sending");
            if (this.formData.refundStatus === 0) {
                await Api.saveNewDraft(this.bankFileId, this.formData);
            } else {
                await Api.saveDraft(this.bankFileId, this.formData);
            }
            this.$waiting.end("refund.sending");
            this.getData();
        },

        async markAsSent() {
            this.activeValidationRules = "sending";
            await this.$nextTick();
            await new Promise(resolve => this.$refs.form.validate(valid => valid && resolve()));
            this.saveDraft();

            const confirm = await this.$dialog
                .title("Bekräftelse")
                .confirm("You have to first refund the amount AND THEN come back here to mark is as sent. Are you sure that you want to mark is as sent?");
            if (!confirm) return;

            this.$waiting.start("refund.sending");
            await Api.markAsSent(this.bankFileId, this.formData);
            this.$waiting.end("refund.sending");
            this.getData();
        },

        async refund() {
            this.activeValidationRules = "sending";
            await this.$nextTick();
            await new Promise(resolve => this.$refs.form.validate(valid => valid && resolve()));
            this.saveDraft();

            const confirm = await this.$dialog.title("Bekräftelse").confirm("Are you sure that you want to refund?");
            if (!confirm) return;

            this.$waiting.start("refund.sending");
            await Api.sendRefund(this.bankFileId, this.formData);
            this.$waiting.end("refund.sending");
            this.getData();
        },

        async deleteRefund() {
            this.$waiting.start("refund.deleting");
            await Api.deleteRefund(this.bankFileId);
            this.$waiting.end("refund.deleting");
            this.resetFormData();
            this.getData();
        },

        resetFormData() {
            this.$refs.form.clearValidate();
            this.formData = {
                reason: null,
                accountType: 0,
                accountNumber: null,
                recipientName: null,
                contactPerson: null,
                amount: null,
                currency: null,
                message: null,
                comment: null,
            };
        },
    },
};
</script>
<style>
.refund-container input[type="text"]:disabled {
    background-color: #fafafa;
    border: none;
    color: #4e4e4e;
}
</style>
